import { invokeApi } from "../../bl_libs/invokeApi";

export const get_user_list_for_affiliate_transaction = async (
  transaction_for,
  search_text,
  delegate_id,
  list_for
) => {
  const requestObj = {
    path: `/api/affiliate_transaction/search?transaction_for=${transaction_for}&search_text=${search_text}&user_id=${
      delegate_id ? delegate_id : ""
    }&list_for=${list_for ? list_for : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_affiliate_transaction = async (data) => {
  const requestObj = {
    path: `/api/affiliate_transaction/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const affiliate_transaction_list = async (page, rowsPerPage, search) => {
  const requestObj = {
    path: `/api/affiliate_transaction/?page=${page}&limit=${rowsPerPage}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Commission_list = async (page, rowsPerPage, search, data) => {
  const requestObj = {
    path: `/admin_users/commission_list_for_admin?page=${page}&limit=${rowsPerPage}&search_text=${
      search == undefined || search == "" ? "" : search
    }`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const affiliate_transaction_detail = async (id) => {
  const requestObj = {
    path: `/api/affiliate_transaction/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_affiliate_transaction = async (id) => {
  const requestObj = {
    path: `/api/affiliate_transaction/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_affiliate_transaction = async (data, id) => {
  const requestObj = {
    path: `/api/affiliate_transaction/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
