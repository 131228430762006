import * as React from "react";

import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { activeMemberListing } from "../../DAL/member/member";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";

import { addFaqApi } from "src/DAL/FAQ/FaqApi";
import {
  paymentPlansCommissionInfo,
  paymentPlansUPdateCommission,
} from "src/DAL/paymentPlan/paymentPlan";
import { useEffect } from "react";
// import MWEditor from "src/components/Editor/CKEditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageCommissionAccess() {
  const navigate = useNavigate();
  const classes = useStyles();
  const pageId = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [planName, setPlanName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [accessFor, setAccessFor] = useState("");

  const [inputs, setInputs] = React.useState({
    Commission_type: "fixed",
    delegates_commission: "",
    public_user_commssion: "",
  });

  const getCommission = async () => {
    setIsLoading(true);
    const result = await paymentPlansCommissionInfo(pageId.id);
    if (result.code === 200) {
      console.log(result, "list of member");
      setAccessFor(result.affiliate_link_access_for);
      setPlanName(result.plan_title);
      if (
        result.commission_info &&
        Object.keys(result.commission_info).length > 0
      ) {
        setInputs(result.commission_info);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      Commission_type: inputs.Commission_type,
    };
    if (accessFor == "public") {
      data.public_user_commssion = inputs.public_user_commssion;
      data.delegates_commission = 0;
    } else if (accessFor == "delegate") {
      data.delegates_commission = inputs.delegates_commission;
      data.public_user_commssion = 0;
    } else {
      data.delegates_commission = inputs.delegates_commission;
      data.public_user_commssion = inputs.public_user_commssion;
    }
    let postData = {
      commission_info: data,
    };

    setIsLoading(true);
    const result = await paymentPlansUPdateCommission(pageId.id, postData);
    if (result.code === 200) {
      console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      console.log(result);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getCommission();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mt-1"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {planName && <span className="title">{planName}</span>}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Manage Commission Access</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Commission Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="Commission_type"
                value={inputs.Commission_type}
                label="Commission Type"
                onChange={handleChange}
              >
                <MenuItem value="fixed">Fixed Amount</MenuItem>
                <MenuItem value="percentage">Percentage Amount</MenuItem>
              </Select>
            </FormControl>
          </div>

          {(accessFor == "both" || accessFor == "delegate") && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Success Ambassador Commission"
                variant="outlined"
                fullWidth
                required
                name="delegates_commission"
                value={inputs.delegates_commission}
                type="text"
                step="any"
                InputProps={{
                  endAdornment: (
                    <span style={{ color: "#f6bd4b" }}>
                      {inputs.Commission_type == "percentage" ? "%" : ""}
                    </span>
                  ),
                  inputProps: {
                    min: 0,
                    max: inputs.Commission_type == "percentage" && 100,
                  },
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  if (
                    value === "" ||
                    (inputs.Commission_type !== "percentage" &&
                      /^\d+(\.\d{0,2})?$/.test(value)) ||
                    (inputs.Commission_type === "percentage" &&
                      /^\d+(\.\d{0,2})?$/.test(value) &&
                      value >= 0 &&
                      value <= 100)
                  ) {
                    handleChange(event);
                  }
                }}
              />
            </div>
          )}
          {(accessFor == "both" || accessFor == "public") && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Public User Commission"
                variant="outlined"
                fullWidth
                required
                type="text"
                step="any"
                name="public_user_commssion"
                value={inputs.public_user_commssion}
                onChange={(event) => {
                  const value = event.target.value;
                  if (
                    value === "" ||
                    (inputs.Commission_type !== "percentage" &&
                      /^\d+(\.\d{0,2})?$/.test(value)) ||
                    (inputs.Commission_type === "percentage" &&
                      /^\d+(\.\d{0,2})?$/.test(value) &&
                      value >= 0 &&
                      value <= 100)
                  ) {
                    handleChange(event);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <span style={{ color: "#f6bd4b" }}>
                      {inputs.Commission_type == "percentage" ? "%" : ""}
                    </span>
                  ),
                  inputProps: {
                    min: 0,
                    max: inputs.Commission_type == "percentage" && 100,
                    pattern: "^\\d*(\\.\\d{0,2})?$", // This pattern allows only positive float values
                  },
                }}
              />
            </div>
          )}

          <div className="text-end mt-4">
            <button className="small-contained-button">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
}
