import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
// material
import { Stack } from "@mui/material";
import roundFilterList from "@iconify/icons-ic/round-filter-list";

// components
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { project_type, s3baseUrl } from "src/config/config";
import {
  deleteTransactionApi,
  transactionListApi,
} from "src/DAL/Transaction/transactionApi";
import moment from "moment";
import { convertCurrencyToSign } from "src/utils/constant";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import TransactionFilter from "./TransactionFilter";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function PaymentTransactionsList() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isAllDates, setIsAllDates] = useState(false);

  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const EMPTY_FILTER = {
    transaction_type: "all",
    sale_page: null,
    payment_plan: null,

    transaction_mode:
      project_type == "dev" ? "sandBox" : project_type == "dev" ? live : "all",
    transaction_status: "succeeded",
    // transaction_status: [
    //   {
    //     name: "Succeeded",
    //     value: "succeeded",
    //     class: "succeeded_bg",
    //     chip_label: "Succeeded",
    //     chip_value: "succeeded",
    //   },
    // ],
    start_date: null,
    end_date: null,
    date: null,
    payment_request: [],
    search_text: searchText,
    filter_by_plateform: null,
    // country: null,
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "email", label: "Member Email", alignRight: false },
    { id: "sub_amount", label: "Sub Amount" },
    { id: "tax_percentage", label: "Tax %" },
    { id: "tax_amount", label: "Tax Amount" },
    { id: "amount", label: "Total Amount" },
    { id: "referral", label: "Referral User" },
    {
      id: "referral_commission",
      label: "Referral Commission",
    },
    { id: "transaction_mode", label: "Transaction Mode" },
    { id: "transaction_date", label: "Transaction Date" },
    { id: "created_by", label: "Created By" },
    { id: "currency", label: "Currency" },
    { id: "status", label: "Status" },
    { id: "transaction_type", label: "Transaction Type" },
    { id: "regsiter_link", label: "Register Link" },
    {
      id: "action",
      label: "Action",
      alignRight: false,
      MENU_OPTIONS: "MENU_OPTIONS",
      type: "action",
    },
    // { id: "action", label: "Action", alignRight: false },
  ];
  const handleDownload = (value) => {
    console.log(value, "download it ");
    if (value.invoice != "") {
      window.open(s3baseUrl + value.invoice, "_blank");
    } else {
      enqueueSnackbar("Invoice does not exist", { variant: "error" });
    }
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteTransactionApi(deleteDoc.id);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Download Invoice",
      icon: "mingcute:file-download-line",
      handleClick: handleDownload,
    },
  ];
  const getConsultantListing = async (filter_data) => {
    setIsLoading(true);
    handleCloseFilterDrawer();
    try {
      setIsLoading(true);
      let postData = {
        // search_text: searchText,
        search_text: filter_data.search
          ? filter_data.search
          : filter_data.search_text,
        transaction_type: filter_data.transaction_type,
        transaction_status: filter_data.transaction_status,
        transaction_mode: filter_data.transaction_mode,
        start_date: filter_data.start_date
          ? moment(filter_data.start_date).format("YYYY-MM-DD")
          : null,
        end_date: filter_data.end_date
          ? moment(filter_data.end_date).format("YYYY-MM-DD")
          : null,
        platform: filter_data?.filter_by_plateform?.name,
        sale_page: filter_data.sale_page,
        payment_request: filter_data.payment_request,
      };
      if (postData.transaction_type == "sale_page") {
        postData.sale_page = filter_data?.sale_page?._id;
      } else {
        postData.sale_page = "";
      }
      if (postData.transaction_type == "payment_request") {
        postData.payment_request = filter_data.payment_request.map((item) => {
          return item._id;
        });
      }

      if (postData.filter_by_plateform) {
        postData.filter_by_plateform = filter_data.filter_by_plateform.name;
      }
      if (postData.transaction_type !== "sale_page") {
        postData.sale_page = null;
        // postData.payment_plan = null;
      }
      if (postData.transaction_type !== "payment_request") {
        postData.payment_request = [];
      }
      const result = await transactionListApi(page, rowsPerPage, postData);
      if (result.code === 200) {
        setFilterDrawerState(false);
        setIsLoading(false);
        const data = result.transaction.map((transaction) => {
          let regsiter_link = "N/A";
          if (transaction.regsiter_link && transaction.regsiter_link !== "") {
            regsiter_link = transaction.regsiter_link;
          }
          let referral;
          let affiliate_user_created_for;
          let affiliate_url_name;
          if (!!transaction.affiliate) {
            referral =
              transaction?.affiliate?.affiliate_user_info?.first_name +
              " " +
              transaction?.affiliate?.affiliate_user_info?.last_name;
            affiliate_url_name = transaction?.affiliate?.affiliate_url_name;
            if (
              transaction?.affiliate?.affiliate_user_info
                ?.affiliate_user_created_for == "consultant"
            ) {
              affiliate_user_created_for = "Success Ambassador";
            } else {
              affiliate_user_created_for = "Public User";
            }
          }
          return {
            id: transaction._id,
            amount:
              convertCurrencyToSign(transaction?.currency) +
              transaction?.amount,
            sub_amount: transaction?.sub_amount
              ? convertCurrencyToSign(transaction?.currency) +
                transaction?.sub_amount
              : 0,
            tax_percentage: transaction.tax_percentage
              ? transaction.tax_percentage
              : 0,
            tax_amount: transaction?.tax_amount
              ? convertCurrencyToSign(transaction?.currency) +
                transaction?.tax_amount.toFixed(2)
              : 0,
            email: transaction?.member?.email,
            transaction_type:
              transaction?.transaction_type == "payment_plan"
                ? "sale_page"
                : transaction?.transaction_type,
            transaction_date: transaction?.transaction_date
              ? moment(transaction?.transaction_date).format("DD-MM-YYYY")
              : "N/A",
            created_by: transaction?.created_by,
            currency: transaction?.currency,
            refUser: transaction?.affliliate,
            transaction_mode: transaction?.transaction_mode,
            status: transaction?.transaction_status,
            regsiter_link: regsiter_link,
            referral_commission:
              convertCurrencyToSign(transaction?.currency) +
              transaction?.referral_commission.toFixed(2),
            referral: referral,
            affiliate_url_name: affiliate_url_name,
            affiliate_user_created_for: affiliate_user_created_for,
            invoice: transaction?.general_invoice_url,
          };
        });
        const csvExportData = result.transaction.map((transaction) => {
          return {
            // ...transaction,
            member_email: transaction?.member?.email,
            amount: transaction?.amount,
            sub_amount: transaction?.sub_amount,
            tax_percentage: transaction?.tax_percentage,
            tax_amount: transaction?.tax_amount,
            transaction_mode: transaction?.transaction_mode,
            transaction_date: transaction?.transaction_date,
            transaction_status: transaction?.transaction_status,
            country: transaction?.member?.country,
            city: transaction?.member?.city,
            state: transaction?.member?.state,
            street: transaction?.member?.street,
            zip_code: transaction?.member?.zip_code,
            currency: transaction?.currency,
          };
        });
        setCsvData(csvExportData);
        setUserList(data);
        setTotalPages(result.total_pages);
        setTotalCount(result.total_transaction_count);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    // localStorage.setItem("member_page_number", newPage);
  };
  const searchFunction = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    console.log(filterData, "=====filterData");
    getConsultantListing(filterData);
    localStorage.setItem("TransactionListSearch", JSON.stringify(filterData));
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("TransactionListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    getConsultantListing(filter_data);
  }, [rowsPerPage, page]);
  const headers = [
    { label: "Member Email", key: "member_email" },
    { label: "Sub Amount", key: "sub_amount" },
    { label: "Tax %", key: "tax_percentage" },
    { label: "Tax Amount", key: "tax_amount" },
    { label: "Total Amount", key: "amount" },
    { label: "Transaction Mode", key: "transaction_mode" },
    { label: "Transaction Date", key: "transaction_date" },
    { label: "Transaction Status", key: "transaction_status" },
    { label: "Country", key: "country" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Street", key: "street" },
    { label: "ZipCode", key: "zip_code" },
    { label: "Currency", key: "currency" },
  ];

  function download_csv_file(csv, csvFileData) {
    const headerRow = csv.map((column) => column.label);
    const csvRows = [headerRow];

    csvFileData.forEach((row) => {
      const dataRow = csv.map((column) => {
        let value = row[column.key];
        // Ensure that value is a string
        if (value !== null && value !== undefined) {
          value = String(value);
        }

        if (value && value.includes(",")) {
          return `"${value}"`;
        }
        return value;
      });

      csvRows.push(dataRow);
    });

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Transactions.csv";
    hiddenElement.click();
  }
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      transaction_mode: "",
      transaction_status: "",
    };
    setFilterData(clear_data);
    getConsultantListing(clear_data);
    // localStorage.removeItem("TransactionListSearch");
    localStorage.setItem("TransactionListSearch", JSON.stringify(clear_data));
  };
  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };
  const handleExportClick = () => {
    download_csv_file(headers, csvData);
  };
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Transactions</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end">
            {csvData.length > 0 && (
              <button
                className="small-contained-button"
                onClick={handleExportClick}>
                Export Csv &nbsp;&nbsp;{" "}
                <Icon icon={"ic:outline-file-download"} />
              </button>
            )}
            <button
              className="small-contained-button ms-2"
              onClick={handleOpenFilterDrawer}>
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}></Stack>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <TransactionFilter
              filterData={filterData}
              isAllDates={isAllDates}
              setIsAllDates={setIsAllDates}
              handleClearFilter={handleClearFilter}
              handleChangeOthers={handleChangeOthers}
              searchSubmitFilter={searchFunction}
              setFilterData={setFilterData}
            />
          }
        />
      </div>
    </>
  );
}
