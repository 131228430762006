import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  SelfImage,
  Vault,
  Profile,
  Pods,
  Affirmations,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  Calender,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  AffirmationList,
  ClientVideos,
  AdminVideos,
  AddAdminVideos,
  AdminVideoDetail,
  AddClientVideos,
  ClientVideoDetail,
  EditAdminVideos,
  EditClientVideos,
} from "./pages/index";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import AddGratitude from "./pages/Gratitude/AddGratitude";
import AddProgramme from "./pages/Programmes/AddProgramme";
import EditProgramme from "./pages/Programmes/EditProgramme";
import AddLesson from "./pages/Programmes/AddLesson";
import EditLesson from "./pages/Programmes/EditLesson";
import LessonRecordingListing from "./pages/LessonRecording/LessonRecordingListing";
import LessonRecordingDetail from "./pages/LessonRecording/LessonRecordingDetail";
import AddLessonRecording from "./pages/LessonRecording/AddLessonRecording";
import EditLessonRecording from "./pages/LessonRecording/EditLessonRecording";
import { ResourcesCard } from "./pages/LessonRecording/ResourcesCard";
import AddLessonDocument, {
  AddLessonDecoument,
} from "./pages/LessonRecording/AddLessonDocument";
import EditLessonDocument from "./pages/LessonRecording/EditLessonDocument";
import { ProgrammeDocumentList } from "./pages/Programmes/ProgrammeDocumentList";
import AddProgrammeDocument from "./pages/Programmes/AddProgramDocument";
import EditProgrammeDocument from "./pages/Programmes/EditProgrammeDocument";
import { SectionList } from "./pages/section/SectionList";
import ProgrammeReviews from "./pages/ProgramReviews/ProgrammeReviews";
import AddProgrammeReview from "./pages/ProgramReviews/AddProgramReview";
import EditProgrammeReview from "./pages/ProgramReviews/EditProgramReview";
import ProgrammeSectionList from "./pages/Sections/SectionList";
import LockedProgram from "./pages/Programmes/LockedProgramInfo";
import AddProgrammeSection from "./pages/Sections/AddProgrammeSection";
import EditProgrammeSection from "./pages/Sections/EditProgrammeSection";
import ProgrammeAutomatedGroups from "./pages/AutomatedGroups/ProgrammeAutomatedGroup";
import AddProgrammeAutoGroup from "./pages/AutomatedGroups/AddAutoGroup";
import EditProgrammeAutoGroup from "./pages/AutomatedGroups/EditAutoGroup";
import MembersList from "./pages/Members/MembersList";
import AddMember from "./pages/Members/AddMember";
import EditMember from "./pages/Members/EditMember";
import ConsultantList from "./pages/consultant/consultantList";
import AddConsultant from "./pages/consultant/AddConsultant";
import EditConsultant from "./pages/consultant/EditConsultant";
import ChangePassword from "./pages/Members/ChangePassword";
import ChangeConsultantPassword from "./pages/consultant/Changepassword";
import ConsultantRecordings from "./pages/consultant/ConsultantRecordings";
import ConsultantRecordingDetail from "./pages/consultant/RecordingDetail";
import MemberProfile from "./pages/Members/MemberProfile";
import MemberAllTransaction from "./pages/Members/AllTransactions";
import ViewBillingList from "./pages/Members/ViewBilling";
import BillingRequestDetail from "./pages/Members/BillingDetail";
import AddPaymentRequest from "./pages/Members/AddPaymentRequest";
import ManageProgrammeAccess from "./pages/Members/ManageProgrammeAccess";
import ConsultantGroupsList from "./pages/consultant/ConsultantGroups";
import ConsultantGroupDetail from "./pages/consultant/ConsultantGroupDetail";
import ConsultantPodsList from "./pages/consultant/PodsListing";
import MembersListConsultant from "./pages/consultant/MemberList";
import ConsultantGoalStatementList from "./pages/consultant/ConsultantGoalStatement";
import ConsultantPodsDetail from "./pages/consultant/PodsDetail";
import MemberGroupsList from "./pages/Members/MembersGroupList";
import MemberPersonalNote from "./pages/Members/MemberPersonalNote";
import MemberGoalstatementList from "./pages/Members/MemberGoalstatementList";
import PodsListMemberProfile from "./pages/Members/PodsListMemberProfile";
import ConsultantGoalstatementDetail from "./pages/consultant/ConsultantGoalstatementDetail";
import AddQuestions from "./pages/GoalStatement/AddQuestion";
import AddSelfImage from "./pages/SelfImage/AddSelfImage";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import EditQuestions from "./pages/GoalStatement/EditQuestion";
import EditSelfImage from "./pages/SelfImage/EditSelfImage";
import { QuestionsReply } from "./pages/Members/QuestionsReply";
import { AnswerHistory } from "./pages/Members/AnswerHistory";
import NinetyDayQuestion from "./pages/90DayQuestion/90DayQuestion";
import Recordings from "./pages/Recordings/Recordings";
import AddRecordings from "./pages/Recordings/AddRecordings";
import EditRecording from "./pages/Recordings/EditRecording";
import VaultCategory from "./pages/Vault/Vault";
import AddVaultCategory from "./pages/Vault/AddVaultCategory";
import EditVaultCategory from "./pages/Vault/EditVaultCategory";
import ProgrammeRecordingDetail from "./pages/Recordings/RecordingDetail";
import StudySession from "./pages/studySession/StudySession";
import AddStudySession from "./pages/studySession/AddStudySession";
import EditStudySession from "./pages/studySession/EditStudySession";
import StudySessionRecording from "./pages/studySession/StudySessionRecordings";
import AddStudySessionRecording from "./pages/studySession/AddStudySessionRecording";
import EditStudySessionRecording from "./pages/studySession/EditStudySessionRecording";
import QuotesList from "./pages/Quotes/Quotes";
import AddQuotes from "./pages/Quotes/AddQuotes";
import EditQuotes from "./pages/Quotes/EditQuotes";
import GratitudeDetail from "./pages/Gratitude/GratitudeDetail";
import EditGratitude from "./pages/Gratitude/EditGratitude";
import Affirmation from "./pages/Affirmation/Affirmation";
import AffirmationDetail from "./pages/Affirmation/AffirmationDetail";
import AddAffirmation from "./pages/Affirmation/AddAffirmation";
import EditAffirmation from "./pages/Affirmation/EditAffirmation";
import AffirmationCategories from "./pages/AffirmationCategories/AffirmationCategories";
import AddAffirmationCategory from "./pages/AffirmationCategories/AddAffirmationCategory";
import EditAffirmationCategory from "./pages/AffirmationCategories/EditAffirmationCategory";
import PaymentTransactionsList from "./pages/Payments/TransactionsList";
import StripeProductsList from "./pages/Payments/StripeProductsList";
import AddStripeProduct from "./pages/Payments/AddStripeProduct";
import EditStripeProduct from "./pages/Payments/EditStripeProduct";
import PaymentTemplateList from "./pages/PaymentTemplate/PaymentTemplateList";
import AddPaymentTemplate from "./pages/PaymentTemplate/AddPaymentTemplate";
import EditPaymentTemplate from "./pages/PaymentTemplate/EditPaymentTemplate";
import PaymentRequestList from "./pages/PaymentRequest/PaymentRequestList";
import AddPaymentRequests from "./pages/PaymentRequest/AddPaymentRequests";
import EditPaymentRequests from "./pages/PaymentRequest/EditPaymentRequests";
import PaymentRequestInfo from "./pages/PaymentRequest/PaymentRequestInfo";
import AddCalendarGroup from "./pages/CalendarGroups/AddCalendarGroups";
import CalendarGroupsList from "./pages/CalendarGroups/CalendarGroupList";
import EditCalendarGroup from "./pages/CalendarGroups/EditCalendarGroups";
import CalendarGroupDetail from "./pages/CalendarGroups/CalendarGroupDetail";
import CalendarPodsList from "./pages/CalendarPods/CalendarPodsList";
import AddPods from "./pages/CalendarPods/AddPods";
import AddCalendarPods from "./pages/CalendarPods/AddPods";
import EditCalendarPods from "./pages/CalendarPods/EditPods";
import CalendarPodsDetail from "./pages/CalendarPods/CalendarPodsDetail";
import AddAutomatedRoomUser from "./pages/CalendarPods/AddRoomUser";
import EditAutomatedRoomUser from "./pages/CalendarPods/EditRoomUser";
import Calenders from "./pages/Calender/Calender";
import ListEvents from "./pages/Calender/ListEvents";
import EventDetailList from "./pages/Calender/EventDetailList";
import DepartmentList from "./pages/Departments/DepartmentsList";
import AddDepartment from "./pages/Departments/AddDepartment";
import AddDepartments from "./pages/Departments/AddDepartment";
import EditDepartments from "./pages/Departments/EditDepartment";
import { SupportTicket } from "./pages/index";
import UpdateGeneralSetting from "./pages/ClientSetting/GeneralSetting";
import ClientDashboardSetting from "./pages/ClientSetting/ClientDashboard";
import SupportTicketDetail from "./pages/SupportTickets/DetailSupportTicket";
import UpdateContentSetting from "./pages/ClientSetting/ContentSetting";
import UpdateConsultantGeneralSetting from "./pages/ConsultantPortal/GeneralSetting";
import AddSendGrid from "./pages/sendGrid/SendGrid";
import AddCompaignSetting from "./pages/SiteSetting/CompaingSetting";
import AddStripeSetting from "./pages/SiteSetting/StripeSetting";
import EmailSetting from "./pages/SiteSetting/EmailSetting";
import InvoiceSetting from "./pages/SiteSetting/InvoiceSetting";
import DefaultSetting from "./pages/SiteSetting/DefaultSetting";
import EmailTemplates from "./pages/SiteSetting/EmailTemplates";
import EditEmailTemplate from "./pages/SiteSetting/EditEmailTemplate";
import { EmailTemplatePreview } from "./pages/SiteSetting/EmailTemplatePreview";
import ToDoTracker from "./pages/Members/ToDoTracker";
import WebsitePages from "./pages/ManageWebiste/Pages";
import AddPages from "./pages/ManageWebiste/AddPages";
import EditPages from "./pages/ManageWebiste/EditPages";
import UpdatePageContent from "./pages/ManageWebiste/UpdatePageContent";
import PaymentPlansList from "./pages/PaymentPlans/PaymentPlansList";
import AddPaymentPlan from "./pages/PaymentPlans/AddpaymentPlan";
import EditPaymentPlan from "./pages/PaymentPlans/EditpaymentPlan";
import PodCastList from "./pages/WebsitePodCasts/PodCastList";
import GeneralPaymentPlansList from "./pages/PaymentPlanGeneral/PaymentPlansList";
import EditProfile from "./pages/EditProfile/EditProfile";
import ImportMembers from "./pages/Members/ImportMember";
import CommunityList from "./pages/Community/CommunityList";
import AddCommunity from "./pages/Community/AddCommunity";
import EditCommunity from "./pages/Community/EditCommunity";
import SeriesVideosList from "./pages/SeriesVideos/SeriesVideosList";
import AddSeriesVideos from "./pages/SeriesVideos/AddSeriesVideos";
import EditSeriesVideos from "./pages/SeriesVideos/EditSeriesVideos";
import FeedsList from "./pages/Feeds/FeedsList";
import AddFeeds from "./pages/Feeds/AddFeed";
import EditFeeds from "./pages/Feeds/EditFeed";
import FeedSetting from "./pages/Feeds/FeedSetting";
import SeriesCategoryList from "./pages/seriesCategories/SeriesCategoryList";
import AddSeriesCategory from "./pages/seriesCategories/AddSeriesCategory";
import EditSeriesCategory from "./pages/seriesCategories/EditSeriesCategory";
import ManageRewardAccess from "./pages/Members/ManageRewardAccess";
import MemoryHistory from "./pages/Members/MemberHistory";
import DynamiteEventsList from "./pages/DynamiteEvents/DynamiteEventsList";
import AddDynamiteEvent from "./pages/DynamiteEvents/AddDynamiteEvent";
import EditDynamiteEvent from "./pages/DynamiteEvents/EditDynamiteEvent";
import DynamiteEventsCategories from "./pages/DynamiteEventsCategories/DynamiteEventsCategories";
import AddDynamiteEventCategory from "./pages/DynamiteEventsCategories/AddDynamiteEventCategory";
import EditDynamiteEventCategory from "./pages/DynamiteEventsCategories/EditDynamiteEventCategory";
import AddDynamiteEventCategoryVideo from "./pages/DynamiteEventCategoryVideos/AddDynamiteEventCategoryVideo";
import DynamiteEventCategoryVideos from "./pages/DynamiteEventCategoryVideos/DynamiteEventCategoryVideos";
import EditDynamiteEventCategoryVideo from "./pages/DynamiteEventCategoryVideos/EditDynamiteEventCategoryVideo";
import AddGeneralPaymentPlan from "./pages/PaymentPlanGeneral/AddGeneralpaymentPlan";
import EditGeneralPaymentPlan from "./pages/PaymentPlanGeneral/EditGeneralpaymentPlan";
import ProgrammeCategoriesList from "./pages/ProgrammeCategories/ProgrammeCategoriesList";
// import RegisterEvents from "./pages/Members/RegisterEvents";
import BusinessStrategyList from "./pages/BusinessStrategy/BusinessStrategyList";
import AddBusinessStrategy from "./pages/BusinessStrategy/AddBusinessStrategy";
import EditBusinessStrategy from "./pages/BusinessStrategy/EditBusinessStrategy";
import AddWebPageTestimonial from "./pages/WebPageTestimonials/AddWebPageTestimonial";
import EditWebPageTestimonial from "./pages/WebPageTestimonials/EditWebPageTestimonial";
import WebPageTestimonials from "./pages/WebPageTestimonials/WebPageTestimonials";
import AddWebPageEvent from "./pages/WebPageEvents/AddWebPageEvent";
import EditWebPageEvent from "./pages/WebPageEvents/EditWebPageEvent";
import WebPageEvents from "./pages/WebPageEvents/WebPageEvents";
import AddWebPageResources from "./pages/WebPageResources/AddWebPageResources";
import EditWebPageResources from "./pages/WebPageResources/EditWebPageResources";
import EventMembers from "./pages/DynamiteEvents/EventMembers";
import RegisterEvents from "./pages/DynamiteEvents/RegisterEvents";
import EditDynamiteEventMember from "./pages/DynamiteEvents/EditDynamiteEventMember";
import AddEventMembers from "./pages/DynamiteEvents/AddEventMembers";
import WebsiteProgrammesList from "./pages/WebsiteProgrammes/WebsiteProgrammesList";
import AddWebsiteProgramme from "./pages/WebsiteProgrammes/AddWebsiteProgramme";
import EditWebsiteProgramme from "./pages/WebsiteProgrammes/EditWebsiteProgramme";
import EditProgrammeCategories from "./pages/ProgrammeCategories/EditProgrammeCategoies";
import AddProgrammeCategories from "./pages/ProgrammeCategories/AddProgrammeCategoies";
import ChartJs from "./components/charts/ChartToDo";
import EventSubscribers from "./pages/Members/EventSubscriber";
import { QuestionSurvey } from "./pages/QuestionSurvey/QuestionSurvey";
import PodcastsList from "./pages/Podcasts/PodCastList";
import AddPodCast from "./pages/Podcasts/AddPodCast";
import EditPodCast from "./pages/Podcasts/EditPodCast";
// import AddGeneralPaymentPlan from "./pages/PaymentPlanGeneral/AddGeneralpaymentPlan";
// import EditGeneralPaymentPlan from "./pages/PaymentPlanGeneral/EditGeneralpaymentPlan";
import SalePageTestimonials from "./pages/salePageTestimonial/SalePageTestimonial";
import AddGeneralTestimonial from "./pages/salePageTestimonial/AddGeneralTestimonial";
import EditSalePageTestimonial from "./pages/salePageTestimonial/EditGeneralTestimonial";
// import ProgrammeCategoriesList from "./pages/ProgrammeCategories/ProgrammeCategoriesList";
// import WebsiteProgrammesList from "./pages/WebsiteProgrammes/WebsiteProgrammesList";
// import AddWebsiteProgramme from "./pages/WebsiteProgrammes/AddWebsiteProgramme";
// import EditWebsiteProgramme from "./pages/WebsiteProgrammes/EditWebsiteProgramme";
// import AddProgrammeCategories from "./pages/ProgrammeCategories/AddProgrammeCategoies";
// import EditProgrammeCategories from "./pages/ProgrammeCategories/EditProgrammeCategoies";
import GeneralFaqList from "./pages/FAQ/GeneralFaqList";
import AddFAQ from "./pages/FAQ/AddFaq";
import EditFAQ from "./pages/FAQ/EditFaq";
import GeneralTeamList from "./pages/Team/TeamList";
import AddTeam from "./pages/Team/AddTeam";
import EditTeam from "./pages/Team/EditTeam";
import GeneralPageTestimonials from "./pages/salePageTestimonial/GeneralTestimonial";
import MediaSectionList from "./pages/MediaSection/MediaBrandsList";
import AddMediaSection from "./pages/MediaSection/AddMedia";
import EditMediaSection from "./pages/MediaSection/EditMedia";
import SuccessProductList from "./pages/SuccessProduct/SuccessProductList";
import AddSuccessProduct from "./pages/SuccessProduct/AddSuccessProduct";
import EditSuccessProduct from "./pages/SuccessProduct/EditSuccessProduct";
import ConsultantCalenders from "./pages/consultant/ConsultantCalender";
import ConsultantListEvents from "./pages/consultant/ConsultantListEvents";
import WhatsIncludeList from "./pages/WhatsInclude/WhatsIncludeList";
import AddWhatsInclude from "./pages/WhatsInclude/AddWhatsInclude";
import EditWhatsInclude from "./pages/WhatsInclude/EditWhatsInclude";
import MediaSections from "./pages/MediaSection/MediaSectionTabs";
import SocialSharingCenter from "./pages/ManageWebiste/soicalSharingCenter";
import ProgrammeBulkAccess from "./components/programAccesBulk/ProgrammeBulkAccess";
import WebPageResources from "./pages/WebPageResources/WebPageResources";
import DetailSelfImage from "./pages/SelfImage/DetailSelfImage";
import MemberSelfImageList from "./pages/Members/MemberSelfImageList";
import { SelfImageAnswerHistory } from "./pages/Members/SelfImageAnswerHistory";
import { SelfImageQuestionReply } from "./pages/Members/SelfImageQuestionReply";
import JournalPrompts from "./pages/JournalPrompts/JournalPrompts";
import AddJournalPrompts from "./pages/JournalPrompts/AddJournalPrompts";
import EditJournalPrompts from "./pages/JournalPrompts/EditJournalPrompts";
import DetailJournalPrompts from "./pages/JournalPrompts/DetailJournalPrompts";
import MemberJournalPromptList from "./pages/Members/MemberJournalPromptList";
import { JournalAnswerHistory } from "./pages/Members/JournalAnswerHistory";
import { JournalQuestionReply } from "./pages/Members/JournalQuestionReply";
import TaxList from "./pages/Tax/TaxList";
import AddState from "./pages/States/AddState";
// import StatesList from "./pages/States/StatesList";
import EditState from "./pages/States/EditState";
import StatesListing from "./pages/States/StatesListing";
import Events from "./pages/ClientSetting/Events";
import AddEvent from "./pages/ClientSetting/AddEvent";
import EditEvent from "./pages/ClientSetting/EditEvent";
import Members from "./pages/ClientSetting/Members";
import MemberChangeStartExpiryDate from "./pages/ClientSetting/MemberChangeStartExpiryDate";
import RegisterEventsUsers from "./pages/ClientSetting/RegisterEventsUsers";
import AddEventMember from "./pages/ClientSetting/AddEventMember";
import EditEventMember from "./pages/ClientSetting/EditEventMember";
import ManageCommissionAccess from "./pages/PaymentPlans/ManageCommissionAccess";
import AffiliateTransactions from "./pages/AffiliateTransactions/AffiliateTransactions";
import AddOrUpdateAffiliateTransactions from "./pages/AffiliateTransactions/AddOrUpdateAffiliateTransactions";
import PendingCommissions from "./pages/AffiliateTransactions/PendingCommissions";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route path="/programs" element={<Programmes />} />
        <Route
          path="/programs/program-detail/:id"
          element={<ProgrammesDetail />}
        />
        <Route path="/programs/add-program" element={<AddProgramme />} />
        <Route path="/programs/edit-program" element={<EditProgramme />} />
        <Route
          path="/programs/lessons-listing/:slug"
          element={<LessonListing />}
        />
        <Route
          path="/programs/lessons-detail/:id"
          element={<LessonsDetail />}
        />
        <Route path="/programs/add-lessons" element={<AddLesson />} />
        <Route path="/programs/edit-lessons/:id" element={<EditLesson />} />
        <Route
          path="/programs/lessons/recordings/:slug"
          element={<LessonRecordingListing />}
        />
        <Route
          path="/programs/lesson-recording-detail/:slug"
          element={<LessonRecordingDetail />}
        />
        <Route
          path="/programs/add-lesson-recording/:slug"
          element={<AddLessonRecording />}
        />
        <Route
          path="/programs/edit-lessons-recording/:slug"
          element={<EditLessonRecording />}
        />
        <Route
          path="/programs/lesson/lesson-resources/:slug"
          element={<ResourcesCard />}
        />
        <Route
          path="/programs/lessons/add-lesson-resource/:slug"
          element={<AddLessonDocument />}
        />
        <Route
          path="/programs/lessons/edit-lesson-resource/:slug"
          element={<EditLessonDocument />}
        />
        {/* programms Doc routes */}
        {/* PodCastList */}
        <Route path="/pod-casts" element={<PodCastList />} />
        {/* PodCastList */}
        <Route path="/website-programs" element={<WebsiteProgrammesList />} />
        <Route
          path="/website-programs/add-program"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-programs/edit-program/:id"
          element={<EditWebsiteProgramme />}
        />
        <Route
          path="/website-categories"
          element={<ProgrammeCategoriesList />}
        />
        <Route
          path="/website-categories/add-category"
          element={<AddProgrammeCategories />}
        />
        <Route
          path="/website-categories/edit-category/:id"
          element={<EditProgrammeCategories />}
        />
        <Route
          path="/website-general-payment-plans"
          element={<GeneralPaymentPlansList />}
        />
        <Route
          path="/website-general-payment-plans/add-payment-plan/"
          element={<AddGeneralPaymentPlan />}
        />
        <Route
          path="/website-general-payment-plans/edit-payment-plan/:id"
          element={<EditGeneralPaymentPlan />}
        />
        {/* <Route
          path="/website-general-payment-plans/add-payment-plan/"
          element={<AddGeneralPaymentPlan />}
        /> */}
        <Route
          path="/website-general-payment-plans/edit-payment-plan/:id"
          element={<EditGeneralPaymentPlan />}
        />
        <Route
          path="/programs/program-resource/:id"
          element={<ProgrammeDocumentList />}
        />
        <Route
          path="/programs/add-program-resource/:slug"
          element={<AddProgrammeDocument />}
        />
        <Route
          path="/template-pages/socail-sharing-setting/:page_slug"
          element={<SocialSharingCenter />}
        />
        <Route
          path="/programs/bulk-access/:program_access_id"
          element={<ProgrammeBulkAccess />}
        />
        <Route path="/programs/:type/:id" element={<ProgrammeBulkAccess />} />
        <Route
          path="/programs/edit-program-resource/:slug"
          element={<EditProgrammeDocument />}
        />
        {/* programms Doc routes end */}
        {/* program > section */}
        <Route path="/programs/sections/:slug" element={<SectionList />} />
        {/* program > section end */}
        {/* Programme reviews */}
        <Route path="/programs/reviews/:slug" element={<ProgrammeReviews />} />
        <Route
          path="/programs/add-review/:slug"
          element={<AddProgrammeReview />}
        />
        <Route
          path="/programs/edit-review/:slug"
          element={<EditProgrammeReview />}
        />
        {/* Programme reviews end*/}
        {/* program section  */}
        <Route
          path="/programs/section/:slug"
          element={<ProgrammeSectionList />}
        />
        <Route
          path="/programs/locked-programme/:slug"
          element={<LockedProgram />}
        />
        <Route
          path="/programs/add-section/:slug"
          element={<AddProgrammeSection />}
        />
        <Route
          path="/programs/edit-section/:slug"
          element={<EditProgrammeSection />}
        />
        {/* program section end */}
        {/* ProgrammeAutomatedGroups */}
        <Route
          path="/programs/automated-group/:slug"
          element={<ProgrammeAutomatedGroups />}
        />
        <Route
          path="/programs/add-automated-group/:slug"
          element={<AddProgrammeAutoGroup />}
        />
        <Route
          path="/programs/edit-automated-group/:slug"
          element={<EditProgrammeAutoGroup />}
        />
        {/* ProgrammeAutomatedGroups end  */}
        {/* Member */}
        <Route path="/member" element={<MembersList />} />
        <Route path="/member/import-member" element={<ImportMembers />} />
        <Route
          path="/dynamite-events/register-events"
          element={<RegisterEvents />}
        />
        <Route path="/member/add-member" element={<AddMember />} />
        <Route path="/member/edit-member/:id" element={<EditMember />} />
        <Route path="/member/member-profile/:id" element={<MemberProfile />} />
        <Route path="/member/member-history/:id" element={<MemoryHistory />} />
        <Route
          path="/member/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/consultant/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/member/member-personal-notes/:id"
          element={<MemberPersonalNote />}
        />
        <Route
          path="/member/member-self-image/:id"
          element={<MemberSelfImageList />}
        />
        <Route
          path="/member/member-journal-prompt/:id"
          element={<MemberJournalPromptList />}
        />
        <Route
          path="/member/member-goal-statement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member/goal-statement-question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route
          path="/member/self-image-question-reply/:id"
          element={<SelfImageQuestionReply />}
        />
        <Route
          path="/member/journal-prompt-question-reply/:id"
          element={<JournalQuestionReply />}
        />
        <Route
          path="/member/event-subscriber/:id"
          element={<EventSubscribers />}
        />
        <Route
          path="/member/goal-statement-Question-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member/self-image-Question-history/:id"
          element={<SelfImageAnswerHistory />}
        />
        <Route
          path="/member/journal-prompt-Question-history/:id"
          element={<JournalAnswerHistory />}
        />
        <Route
          path="/member/program-access/:id"
          element={<ManageProgrammeAccess />}
        />
        <Route
          path="/member/reward-access/:id"
          element={<ManageRewardAccess />}
        />
        <Route
          path="/member/add-payment-request/:id"
          element={<AddPaymentRequest />}
        />
        <Route
          path="/member/billing-detail"
          element={<BillingRequestDetail />}
        />
        <Route
          path="/member/all-transaction/:id"
          element={<MemberAllTransaction />}
        />
        <Route
          path="/member/view-billing-list/:id"
          element={<ViewBillingList />}
        />
        <Route
          path="/member/change-password/:id"
          element={<ChangePassword />}
        />
        <Route path="/member/member-pods" element={<PodsListMemberProfile />} />
        <Route path="/member/to-do-tracker/:id" element={<ToDoTracker />} />
        {/* <Route path="/member/to-do-tracker/:id" element={<ChartJs />} /> */}
        {/* member end  */}
        {/* whats include */}
        <Route
          path="/website-pages/whats-include/:id"
          element={<WhatsIncludeList />}
        />
        <Route
          path="/website-pages/whats-include/add-whats-include/:id"
          element={<AddWhatsInclude />}
        />
        <Route
          path="/website-pages/whats-include/edit-whats-include/:id"
          element={<EditWhatsInclude />}
        />
        {/* consultant   */}
        <Route path="/consultant" element={<ConsultantList />} />
        <Route
          path="/consultant/pods-list/:id"
          element={<ConsultantPodsList />}
        />
        <Route
          path="/consultant/member-list/:id"
          element={<MembersListConsultant />}
        />
        <Route path="/consultant/add-consultant" element={<AddConsultant />} />
        <Route
          path="/consultant/member-profile/:id"
          element={<MemberProfile />}
        />
        <Route
          path="/consultant/groups/:id"
          element={<ConsultantGroupsList />}
        />
        <Route
          path="/consultant/goal-statement-list/:id"
          element={<ConsultantGoalStatementList />}
        />
        <Route
          path="/consultant/self-image-list/:id"
          element={<ConsultantGoalStatementList />}
        />
        <Route
          path="/consultant/events/:id"
          element={<ConsultantCalenders />}
        />
        <Route
          path="/consultant/events-list/:id"
          element={<ConsultantListEvents />}
        />
        <Route
          path="/consultant/pods-detail/:id"
          element={<ConsultantPodsDetail />}
        />
        <Route
          path="/consultant/view-group-detail/:id"
          element={<ConsultantGroupDetail />}
        />
        <Route
          path="/consultant/goal-statement-detail/:id"
          element={<ConsultantGoalstatementDetail />}
        />
        <Route
          path="/consultant/self-image-detail/:id"
          element={<ConsultantGoalstatementDetail />}
        />
        <Route
          path="/consultant/recording-detail/:id"
          element={<ConsultantRecordingDetail />}
        />
        <Route
          path="/consultant/edit-consultant/:id"
          element={<EditConsultant />}
        />
        <Route
          path="/consultant/change-password/:id"
          element={<ChangeConsultantPassword />}
        />
        <Route
          path="/consultant/recordings/:id"
          element={<ConsultantRecordings />}
        />
        {/* consultant end  */}
        {/* /goal-statement start */}
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/self-image" element={<SelfImage />} />
        <Route path="/journal-prompts" element={<JournalPrompts />} />
        <Route path="/goal-statement/add-question" element={<AddQuestions />} />
        <Route path="/self-image/add-self-image" element={<AddSelfImage />} />
        <Route
          path="/journal-prompts/add-journal-prompt"
          element={<AddJournalPrompts />}
        />
        <Route
          path="/goal-statement/detail-question/:id"
          element={<DetailQuestion />}
        />
        <Route
          path="/self-image/detail-self-image/:id"
          element={<DetailSelfImage />}
        />
        <Route
          path="/journal-prompts/detail-journal-prompt/:id"
          element={<DetailJournalPrompts />}
        />
        <Route
          path="/goal-statement/edit-question/:slug"
          element={<EditQuestions />}
        />
        <Route
          path="/self-image/edit-self-image/:slug"
          element={<EditSelfImage />}
        />
        <Route
          path="/journal-prompts/edit-journal-prompt/:slug"
          element={<EditJournalPrompts />}
        />
        {/* /goal-statement end */}
        {/* /website-pages */}
        <Route path="/website-pages" element={<WebsitePages />} />
        <Route path="/website-team" element={<GeneralTeamList />} />
        <Route path="/website-team/add-team" element={<AddTeam />} />
        <Route path="/website-team/edit-team/:id" element={<EditTeam />} />
        <Route path="/faqs" element={<GeneralFaqList />} />
        <Route path="/faqs/add-faq" element={<AddFAQ />} />
        <Route path="/faqs/edit-faq/:id" element={<EditFAQ />} />{" "}
        <Route
          path="/website-pages/sale-page-faq/:id"
          element={<GeneralFaqList />}
        />
        <Route
          path="/website-pages/sale-page-resources/:id"
          element={<WebPageResources />}
        />
        <Route
          path="/website-pages/add-sale-page-faq/:id"
          element={<AddFAQ />}
        />
        <Route
          path="/website-pages/add-sale-page-resources/:id"
          element={<AddWebPageResources />}
        />
        <Route
          path="/website-pages/:page_id/edit-sale-page-faq/:id"
          element={<EditFAQ />}
        />
        <Route
          path="/website-pages/:page_id/edit-sale-page-resources/:id"
          element={<EditWebPageResources />}
        />
        <Route
          path="/website-pages/question/:id"
          element={<QuestionSurvey />}
        />
        <Route
          path="/website-pages/sale-testimonial/:page_id"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/website-pages/sale-testimonial/add-sale-testimonial/:page_id"
          element={<AddWebPageTestimonial />}
        />
        <Route
          path="/website-pages/sale-testimonial/add-sale-testimonial/:page_id"
          element={<AddWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/edit-sale-testimonial/:id"
          element={<EditWebPageTestimonial />}
        />
        <Route path="/business-strategy" element={<BusinessStrategyList />} />
        <Route
          path="/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/business-strategy/:id"
          element={<EditBusinessStrategy />}
        />
        <Route
          path="/website-pages/payment-plans/:id"
          element={<PaymentPlansList />}
        />
        <Route
          path="/website-pages/sale-page/:page_id"
          element={<WebPageEvents />}
        />
        <Route
          path="/website-pages/add-page-event/:page_id"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-pages/:page_id/edit-page-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route
          path="/website-pages/payment-plans/add-payment-plan/:id"
          element={<AddPaymentPlan />}
        />
        <Route path="/website-events" element={<WebPageEvents />} />
        <Route
          path="/website-events/add-website-event"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-events/edit-website-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route path="/website-resources" element={<WebPageResources />} />
        <Route
          path="/website-resources/add-website-resources"
          element={<AddWebPageResources />}
        />
        <Route
          path="/website-resources/edit-website-resources/:id"
          element={<EditWebPageResources />}
        />
        <Route path="/website-pages/add-page" element={<AddPages />} />
        <Route path="/website-pages/edit-page" element={<EditPages />} />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        {/* <Route path="/website-testimonials" element={<WebPageTestimonials />} /> */}
        <Route
          path="/website-testimonials/add-website-testimonial"
          element={<AddWebPageTestimonial />}
        />
        <Route
          path="/website-testimonials/edit-website-testimonial/:id"
          element={<EditWebPageTestimonial />}
        />
        <Route
          path="/website-testimonials/sale-testimonial/:page_id"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/website-pages/sale-testimonial/add-sale-testimonial/:page_id"
          element={<AddWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/edit-sale-testimonial/:id"
          element={<EditWebPageTestimonial />}
        />
        {/* <Route
          path="/website-testimonials/sale-testimonial/:id"
          element={<SalePageTestimonials />}
        /> */}
        <Route
          path="/website-testimonials"
          element={<GeneralPageTestimonials />}
        />
        <Route
          path="/website-testimonials/add-testimonial"
          element={<AddWebPageTestimonial />}
        />
        <Route
          path="/website-testimonials/edit-testimonial/:id"
          element={<EditSalePageTestimonial />}
        />
        {/* /90-day-questions */}
        <Route path="/90-day-questions" element={<NinetyDayQuestion />} />
        {/* /90-day-questions end */}
        {/* ProgrammeCategories */}
        <Route
          path="/websiteProgramCategories"
          element={<ProgrammeCategoriesList />}
        />
        {/* ProgrammeCategories */}
        <Route
          path="/websiteProgramCategories"
          element={<ProgrammeCategoriesList />}
        />
        {/* recording /recordings */}
        <Route path="/recording-list" element={<Recordings />} />
        <Route
          path="/recording-list/recording-detail"
          element={<ProgrammeRecordingDetail />}
        />
        <Route
          path="/recording-list/add-recording"
          element={<AddRecordings />}
        />
        <Route
          path="/recording-list/edit-recording"
          element={<EditRecording />}
        />
        {/* recording end */}
        {/* vault VaultCategory */}
        <Route path="/categories" element={<VaultCategory />} />
        <Route path="/categories/add-category" element={<AddVaultCategory />} />
        <Route
          path="/categories/edit-category"
          element={<EditVaultCategory />}
        />
        {/* vault end */}
        {/* community */}
        <Route path="/community" element={<CommunityList />} />
        <Route
          path="/community/add-community-reward"
          element={<AddCommunity />}
        />
        <Route
          path="/community/edit-community-reward/:id"
          element={<EditCommunity />}
        />
        <Route path="/series-categories/" element={<SeriesCategoryList />} />
        <Route
          path="/series-categories/add-category"
          element={<AddSeriesCategory />}
        />
        <Route
          path="/series-categories/edit-category/:id"
          element={<EditSeriesCategory />}
        />
        {/* community end */}
        {/* /website-podcasts */}
        <Route path="/website-podcasts" element={<PodcastsList />} />
        <Route path="/website-podcasts/add-podcast" element={<AddPodCast />} />
        <Route
          path="/website-podcasts/edit-podcast/:id"
          element={<EditPodCast />}
        />
        {/* /series-videos */}
        <Route path="/series-videos" element={<SeriesVideosList />} />
        <Route
          path="/series-videos/add-series-videos"
          element={<AddSeriesVideos />}
        />
        <Route
          path="/series-videos/edit-series-videos/:id"
          element={<EditSeriesVideos />}
        />
        {/* fees */}
        <Route path="/community-news-feed" element={<FeedsList />} />
        <Route path="/feeds/add-feed" element={<AddFeeds />} />
        <Route path="/feeds/edit-feed/:id" element={<EditFeeds />} />
        <Route path="/feed/setting" element={<FeedSetting />} />
        {/* Sessions end */}
        <Route path="/sessions" element={<StudySession />} />
        <Route path="/sessions/add-session" element={<AddStudySession />} />
        <Route path="/sessions/edit-session" element={<EditStudySession />} />
        <Route path="/session-recording" element={<StudySessionRecording />} />
        <Route
          path="/session-recording/add-study-session-recording"
          element={<AddStudySessionRecording />}
        />
        <Route
          path="/session-recording/edit-study-session-recording"
          element={<EditStudySessionRecording />}
        />
        {/* Sessions */}
        {/* QuotesList */}
        <Route path="/quotesList" element={<QuotesList />} />
        <Route path="/quotesList/add-quotes" element={<AddQuotes />} />
        <Route path="/quotesList/edit-quotes" element={<EditQuotes />} />
        {/* QuotesList end */}
        {/* gratitude  */}
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/gratitude/add-gratitude" element={<AddGratitude />} />
        <Route path="/gratitude/edit-gratitude" element={<EditGratitude />} />
        <Route
          path="/gratitude/gratitude-detail/:id"
          element={<GratitudeDetail />}
        />
        {/* gratitude end */}
        {/* Affirmation  */}
        <Route path="/affirmation" element={<Affirmation />} />
        <Route
          path="/affirmation/add-affirmation"
          element={<AddAffirmation />}
        />
        <Route
          path="/affirmation/edit-affirmation"
          element={<EditAffirmation />}
        />
        <Route
          path="/affirmation/affirmation-detail/:id"
          element={<AffirmationDetail />}
        />
        {/* Affirmation end */}
        {/* /affirmation-categories */}
        <Route
          path="/affirmations-categories"
          element={<AffirmationCategories />}
        />
        <Route
          path="/affirmations-categories/add-category"
          element={<AddAffirmationCategory />}
        />
        <Route
          path="/affirmations-categories/edit-category"
          element={<EditAffirmationCategory />}
        />
        {/* /affirmation-categories end */}
        {/* PaymentTransactions */}
        <Route
          path="/payment-transactions"
          element={<PaymentTransactionsList />}
        />
        <Route
          path="/affiliate-transactions"
          element={<AffiliateTransactions />}
        />{" "}
        <Route path="/commission" element={<PendingCommissions />} />
        <Route
          path="/affiliate-transactions/add-transaction"
          element={<AddOrUpdateAffiliateTransactions />}
        />
        <Route path="/stripe-products" element={<StripeProductsList />} />
        <Route
          path="/stripe-products/add-product"
          element={<AddStripeProduct />}
        />
        <Route
          path="/stripe-products/edit-product/:id"
          element={<EditStripeProduct />}
        />
        {/* PaymentTransactions end */}
        {/* PaymentTemplateList  */}
        <Route path="/payment-template" element={<PaymentTemplateList />} />
        <Route
          path="/website-pages/payment-plans/edit-payment-plan/:id"
          element={<EditPaymentPlan />}
        />
        <Route
          path="/website-pages/payment-plans/manage-commission/:id"
          element={<ManageCommissionAccess />}
        />
        <Route
          path="/payment-template/add-template"
          element={<AddPaymentTemplate />}
        />
        <Route
          path="/payment-template/edit-template/:id"
          element={<EditPaymentTemplate />}
        />
        {/* PaymentTemplateList end  */}
        {/* PaymentRequest   */}
        <Route path="/payment-request" element={<PaymentRequestList />} />
        <Route
          path="/payment-request/add-payment-request"
          element={<AddPaymentRequests />}
        />
        <Route
          path="/payment-request/payment-request-detail"
          element={<PaymentRequestInfo />}
        />
        <Route
          path="/payment-request/edit-payment-request/:id"
          element={<EditPaymentRequests />}
        />
        {/* PaymentRequest end   */}
        {/* Calendar    AddCalendarGroup*/}
        <Route path="/calendar-groups" element={<CalendarGroupsList />} />
        <Route
          path="/calendar-groups/group-detail/:id"
          element={<CalendarGroupDetail />}
        />
        <Route
          path="/calendar-groups/add-group"
          element={<AddCalendarGroup />}
        />
        <Route
          path="/calendar-groups/edit-group/:id"
          element={<EditCalendarGroup />}
        />
        {/*Calendar end  */}
        {/*DepartmentList   */}
        <Route path="/departments" element={<DepartmentList />} />
        <Route
          path="/departments/add-department"
          element={<AddDepartments />}
        />
        <Route
          path="/departments/edit-department/:id"
          element={<EditDepartments />}
        />
        {/*DepartmentList end  */}
        {/*SupportTicket   */}
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route
          path="/support-ticket/detail/:id"
          element={<SupportTicketDetail />}
        />
        {/*SupportTicket end  */}
        {/*Calendarpods  */}
        <Route path="/community-rooms" element={<CalendarPodsList />} />
        <Route path="/calendarEvents" element={<Calenders />} />
        <Route path="/calendarEvents/event-list" element={<ListEvents />} />
        {/* <Route path="/calendar/event-list" element={<ListEvents />} /> */}
        <Route path="/calendar/event-detail" element={<EventDetailList />} />
        <Route path="/community-rooms/add-room" element={<AddCalendarPods />} />
        <Route
          path="/community-rooms/edit-room/:id"
          element={<EditCalendarPods />}
        />
        <Route
          path="/community-rooms/rooms-detail/:id"
          element={<CalendarPodsDetail />}
        />
        <Route
          path="/community-rooms/add-room-user/:id"
          element={<AddAutomatedRoomUser />}
        />
        <Route
          path="/community-rooms/edit-room-user/:id"
          element={<EditAutomatedRoomUser />}
        />
        {/*Calendarpods  end*/}
        {/* /general-setting */}
        <Route path="/general-setting" element={<UpdateGeneralSetting />} />
        <Route path="/client-dashboard" element={<ClientDashboardSetting />} />
        <Route path="/content-setting" element={<UpdateContentSetting />} />
        <Route path="/danielle-events" element={<Events />} />
        <Route
          path="/danielle-events/add-danielle-event"
          element={<AddEvent />}
        />
        <Route
          path="/danielle-events/edit-danielle-event/:event_slug"
          element={<EditEvent />}
        />
        <Route path="/danielle-events/:id/members" element={<Members />} />
        <Route
          path="/danielle-events/change-start-date/:start_date_event"
          element={<MemberChangeStartExpiryDate />}
        />
        <Route
          path="/danielle-events/change-expiry-date/:expiry_date_event"
          element={<MemberChangeStartExpiryDate />}
        />
        <Route
          path="/danielle-events/register-events/:event_id"
          element={<RegisterEventsUsers />}
        />
        <Route
          path="/danielle-events/:event_id/add-members"
          element={<AddEventMember />}
        />
        <Route
          path="/danielle-events/:event_id/edit-member/:member_id"
          element={<EditEventMember />}
        />
        <Route path="/website-events" element={<WebPageEvents />} />
        <Route path="/website-events/add-event" element={<AddWebPageEvent />} />
        <Route
          path="/dynamite-events/:event_id/general-events/add-event"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-events/edit-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route
          path="/dynamite-events/:event_id/general-events/:id"
          element={<EditWebPageEvent />}
        />
        <Route path="/dynamite-events" element={<DynamiteEventsList />} />
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events"
          element={<WebPageEvents />}
        />
        <Route
          path="/dynamite-events/:event_id/members"
          element={<EventMembers />}
        />
        <Route
          path="/dynamite-events/:event_id/add-members"
          element={<AddEventMembers />}
        />
        <Route
          path="/dynamite-events/:event_id/members/:member_id"
          element={<EditDynamiteEventMember />}
        />
        <Route
          path="/dynamite-events/add-event"
          element={<AddDynamiteEvent />}
        />
        <Route
          path="/dynamite-events/edit-event/:id"
          element={<EditDynamiteEvent />}
        />
        <Route
          path="/dynamite-event-categories/:event_id"
          element={<DynamiteEventsCategories />}
        />
        <Route
          path="/dynamite-event-categories/:event_id/add-category"
          element={<AddDynamiteEventCategory />}
        />
        <Route
          path="/dynamite-event-categories/:event_id/edit-category/:id"
          element={<EditDynamiteEventCategory />}
        />
        <Route
          path="/dynamite-event-videos/:event_id/:category_id"
          element={<DynamiteEventCategoryVideos />}
        />
        <Route
          path="/dynamite-event-videos/:event_id/:category_id/add-video"
          element={<AddDynamiteEventCategoryVideo />}
        />
        <Route
          path="/dynamite-event-videos/:event_id/:category_id/edit-video/:id"
          element={<EditDynamiteEventCategoryVideo />}
        />
        {/* ConsultantGeneralSetting */}
        <Route path="/send-grid" element={<AddSendGrid />} />
        <Route path="/add-stripe" element={<AddStripeSetting />} />
        <Route path="/email-setting" element={<EmailSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSetting />} />
        <Route path="/default-setting" element={<DefaultSetting />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/add-compaign" element={<AddCompaignSetting />} />
        <Route
          path="/email-templates/preview/:id"
          element={<EmailTemplatePreview />}
        />
        <Route
          path="/email-templates/edit-temail-emplate/:id"
          element={<EditEmailTemplate />}
        />
        {/* media section */}
        {/* <Route path="/media-section" element={<MediaSectionList />} /> */}
        <Route path="/media-section" element={<MediaSections />} />
        <Route path="/media-section/add-media" element={<AddMediaSection />} />
        <Route
          path="/media-section/edit-media/:id"
          element={<EditMediaSection />}
        />
        {/* success product */}
        <Route path="/success-product" element={<SuccessProductList />} />
        <Route
          path="/success-product/add-product"
          element={<AddSuccessProduct />}
        />
        <Route
          path="/success-product/edit-product/:id"
          element={<EditSuccessProduct />}
        />
        <Route
          path="/consultants-general-setting"
          element={<UpdateConsultantGeneralSetting />}
        />
        <Route
          path="/programs/program_recording_detail/:id"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/goal_statement" element={<GoalStatement />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/pods" element={<Pods />} />
        <Route path="/affirmations" element={<Affirmations />} />
        <Route
          path="/affirmations/affirmationList"
          element={<AffirmationList />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/memories/memoriesdetails" element={<MemoriesDetails />} />
        <Route path="/daily_activities" element={<DailyActivities />} />
        <Route path="/calender" element={<Calender />} />
        {/* helping videos */}
        <Route path="/admin-videos" element={<AdminVideos />} />
        <Route
          path="/admin-videos/add-admin-videos"
          element={<AddAdminVideos />}
        />
        <Route
          path="/admin-videos/edit-admin-videos"
          element={<EditAdminVideos />}
        />
        <Route
          path="/client-videos/edit-client-videos"
          element={<EditClientVideos />}
        />
        <Route
          path="/admin-videos/admin-videos-detail/:id"
          element={<AdminVideoDetail />}
        />
        <Route path="/client-videos" element={<ClientVideos />} />
        <Route
          path="/client-videos/add-client-videos"
          element={<AddClientVideos />}
        />
        <Route
          path="/client-videos/client-videos-detail/:id"
          element={<ClientVideoDetail />}
        />
        <Route path="/tax-configuration" element={<TaxList />} />
        <Route path="/states" element={<StatesListing />} />
        <Route path="/states/add-state" element={<AddState />} />
        <Route path="/states/edit-state" element={<EditState />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
