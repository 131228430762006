import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <Icon icon={name} width={22} height={22} className="navbar-icon" />
);

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Programs",
    path: "/programs",
    icon: getIcon(barchartfill),
  },
  {
    title: "Members",
    path: "/member",
    icon: getIcon(barchartfill),
  },
  {
    title: "Success Ambassador",
    path: "/consultant",
    icon: getIcon(barchartfill),
  },
  {
    title: "Goal Statement",
    path: "/goal-statement",
    icon: getIcon(barchartfill),
  },
  {
    title: "Self Image",
    path: "/self-image",
    icon: getIcon(barchartfill),
  },
  {
    title: "Journal Prompts",
    path: "/journal-prompts",
    icon: getIcon(barchartfill),
  },
  {
    title: "FAQs",
    path: "/faqs",
    icon: getIcon("fluent:chat-bubbles-question-24-regular"),
  },
  {
    title: "Tax Configuration",
    path: "/tax-configuration",
    icon: getIcon("iconoir:percentage-circle-solid"),
  },
  {
    title: "Community News Feed",
    path: "/community-news-feed",
    icon: getIcon("iconoir:community"),
  },
  {
    title: "Replay Library",
    icon: getIcon("quill:calendar-more"),
    child_options: [
      {
        title: "Recordings",
        path: "/recording-list",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Categories",
        path: "/categories",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Study Session",
    icon: getIcon("quill:calendar-more"),
    child_options: [
      {
        title: "Sessions",
        path: "/sessions",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Session Recording",
        path: "/session-recording",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Extras",
    icon: getIcon("quill:calendar-more"),
    child_options: [
      {
        title: "Quotes",
        path: "/quotesList",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Gratitude",
        path: "/gratitude",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Affirmation",
        path: "/affirmation",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Affirmation Categories",
        path: "/affirmations-categories",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Payments",
    icon: getIcon("quill:calendar-more"),
    child_options: [
      {
        title: "Stripe Products",
        path: "/stripe-products",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Transactions",
        path: "/payment-transactions",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Payment Templates",
        path: "/payment-template",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Payment Request",
        path: "/payment-request",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Affiliate Transactions",
        path: "/affiliate-transactions",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Commissions",
        path: "/commission",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Calendar",
    icon: getIcon("quill:calendar-more"),
    child_options: [
      {
        title: "Groups",
        path: "/calendar-groups",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Calendar Events",
        path: "/calendarEvents",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Community Rooms",
        path: "/community-rooms",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Support",
    icon: getIcon("quill:calendar-more"),
    child_options: [
      {
        title: "Departments",
        path: "/departments",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Support Tickets",
        path: "/support-ticket",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },

  {
    title: "Client Portal",
    icon: getIcon("ant-design:setting-filled"),
    child_options: [
      {
        title: "General Setting",
        path: "/general-setting",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Client Dashboard",
        path: "/client-dashboard",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Content Setting",
        path: "/content-setting",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Danielle's Events / Portals",
        path: "/danielle-events",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Consultant Portal",
    icon: getIcon("ant-design:setting-filled"),
    child_options: [
      {
        title: "General Setting",
        path: "/consultants-general-setting",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },

  {
    title: "General Setting",
    icon: getIcon("ant-design:setting-filled"),
    child_options: [
      {
        title: "Default Setting",
        path: "/default-setting",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Invoice Setting",
        path: "/invoice-setting",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Email Setting",
        path: "/email-setting",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Stripe Setting",
        path: "/add-stripe",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Campaign Setting",
        path: "/add-compaign",
        icon: getIcon("quill:calendar-more"),
      },
      // {
      //   title: "SendGrid Setting",
      //   path: "/send-grid",
      //   icon: getIcon("quill:calendar-more"),
      // },
      {
        title: "Email Templates",
        path: "/email-templates",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Manage Website",
    icon: getIcon("ant-design:setting-filled"),
    child_options: [
      {
        title: "Pages",
        path: "/website-pages",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Events",
        path: "/website-events",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Resources",
        path: "/website-resources",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Testimonials",
        path: "/website-testimonials",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Payment Plans",
        path: "/website-general-payment-plans",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Website Programs",
        path: "/website-programs",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Program Categories",
        path: "/website-categories",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Website Podcasts",
        path: "/website-podcasts",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Media Section",
        path: "/media-section",
        icon: getIcon("quill:calendar-more"),
      },
      {
        title: "Success Product",
        path: "/success-product",
        icon: getIcon("quill:calendar-more"),
      },
    ],
  },
  {
    title: "Helping Videos",
    icon: getIcon("akar-icons:video"),
    child_options: [
      {
        title: "Admin Videos",
        path: "/admin-videos",
        icon: getIcon("bxs:videos"),
      },
      {
        title: "Client Videos",
        path: "/client-videos",
        icon: getIcon("bxs:videos"),
      },
    ],
  },
];

export default sidebarConfig;
